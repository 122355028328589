import VueSegmentAnalytics from "vue-segment-analytics";
import { SEGMENT_API_KEY } from "@/common/Env";
import store from "@/store/index";

const setupAnalytics = (vue, router) => {
  if (SEGMENT_API_KEY) {
    vue.use(VueSegmentAnalytics, { id: SEGMENT_API_KEY });

    // this is copied from VueSegmentAnalytics sources,
    // node_modules/vue-segment-analytics/src/index.js
    // for some reason afterEeach hook registration it's not working there, but is working here
    const pageCategory = "";

    router.afterEach((to, from) => {
      window.analytics.page(pageCategory, to.name || "", {
        path: to.fullPath,
        referrer: from.fullPath,
      });
    });
  }
};

const setAnalyticsUser = (user, team) => {
  if (!window.analytics) {
    return;
  }

  if (user?.uuid) {
    const nameSplit = user.name.split(" ") || [];
    const firstName = nameSplit[0] || "";
    const lastName = nameSplit[1] || "";

    window.analytics.identify(user.uuid, {
      tfid: user.id,
      name: user.name,
      email: user.email,
      firstName: firstName,
      lastName: lastName,
      company: user.primaryTeamName || "",
      source: "recruiter-app",
      gptReports: user.useGptReports,
      allowHasEmailFilter: user.allowHasEmailFilter,
      allowDiversity: user.allowDiversity,
      agency: team.isAgency,
      recommendationsEnabled: team.recommendationsTabEnabled,
      inboundsEnabled: team.inboundsEnabledAndSupported,
      referralsEnabled: team.introsEnabled,
      onTrial: user.onTrial,
    });
  } else {
    window.analytics.reset();
  }

  if (window.pendo) {
    window.pendo.initialize({
      visitor: {
        id: user.id,
        full_name: user.name,
        email: user.email,
      },
      account: {
        id: team.id,
        name: team.name,
        chatbotEnabled: user.chatbotEnabled,
      }
    });
  }
};

const logEvent = (type, props = {}) => {
  const defaultProps = {};
  const currentUser = store.getters.currentUser;
  const actualUser = store.getters.actualUser;
  if (currentUser.uuid !== actualUser.uuid) {
    // TODO: investigate the possibility of moving this to user
    // and sending with each event automatically
    defaultProps.sudoing_as = currentUser.email;
  }
  defaultProps.subplatform = "vue";
  props = Object.assign(defaultProps, props);

  window.analytics.track(type, props);
};

export default logEvent;

export { setupAnalytics, setAnalyticsUser, logEvent };
