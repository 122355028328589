<script setup>
import { computed, onMounted, ref } from "vue";
import { useGetters } from "vuex-composition-helpers";
import { useRouter } from "vue-router/composables";
import _ from "lodash";

import ProjectsApi from "@/common/http/ProjectsApi";
import OutreachApi from "@/common/http/OutreachApi";

import MessageEditor from "@/components/MessageEditor/Component";
import UserAvatar from "@/components/UserAvatar";

const props = defineProps({
  project: {
    type: Object,
    default: null,
  },
  connectedAtsJob: {
    type: Object,
    default: null,
  },
  hasAtsJob: {
    type: Boolean,
    default: true,
  },
});

const {
  currentTeam,
  currentUser,
  allTeammatesAndMe,
  isReferralsEnabled,
} = useGetters([
  "currentTeam",
  "currentUser",
  "allTeammatesAndMe",
  "isReferralsEnabled",
]);

const router = useRouter();

const emit = defineEmits(
  ["update:project"],
  ["update:connectedAtsJob"],
  ["update:hasAtsJob"]
);

const projectOptions = ref([]);
const locationOptions = ref([]);
const isLoadingRemoteQuery = ref(false);

const descriptionPlaceholder = computed(() => {
  let str = "The description added here serves as the foundation for all AI generated content. We'll use it to extract skills, location, education, years of experience, industry, and more.";
  str += " The more complete it is, the better your messaging and recommended candidates will be.";

  if (isReferralsEnabled.value) {
    str += "\n\nPlease note that text formatting is required only if this job will be posted in the Employee portal for referrals.";
  } else {
    str += "\n\nPlease note that text formatting is not needed.";
  }
  return str;
});

const computedProject = computed({
  get() { return props.project; },
  set(value) { emit("update:project", value); },
});

const computedConnectedAtsJob = computed({
  get() { return props.connectedAtsJob; },
  set(value) { emit("update:connectedAtsJob", value); },
});

const computedHasAtsJob = computed({
  get() { return props.hasAtsJob; },
  set(value) { emit("update:hasAtsJob", value); },
});

const atsJobTooltip = computed(() => {
  return `Choose a ${currentUser.value.primaryTeamAtsName} job to link`;
});

const selectedJobPlaceholder = computed(() => {
  return `Select a ${currentUser.value.primaryTeamAtsName} job to sync`;
});

function viewProject(project) {
  router.push({ name: "job-root", params: { projectId: project.id } });
};

async function remoteQuery(filterId, query = {}) {
  const queryTrimmed = query?.trim() || "";

  if (!isLoadingRemoteQuery.value && queryTrimmed) {
    isLoadingRemoteQuery.value = true;

    const params = { filterId: _.snakeCase(filterId), query: queryTrimmed };
    const { data } = await OutreachApi.searchFiltersQuery(params);

    isLoadingRemoteQuery.value = false;
    return data[filterId].options;
  } else {
    return [];
  }
}

async function remoteLocationQuery(query) {
  locationOptions.value = await remoteQuery("location", query);
};

function querySearch(name, callback) {
  if (name) {
    const results = projectOptions.value.filter((option) => {
      return option.value.toLowerCase().indexOf(name.toLowerCase()) === 0
    });
    callback(results);
  } else {
    callback(projectOptions.value);
  }
}

function jobLabel(job) {
  if (job.isConnected) {
    return `${job.name} - Connected`;
  }
  return job.name;
}

function jobIconStyle(job) {
  if (job.isConnected) {
    return "opacity: 0.75";
  }
  return "opacity: 0";
}

function handleAtsJobChange(atsJobId) {
  let job = computedProject.value.atsJobOptions.find(job => job.id === atsJobId);
  if (job?.isConnected) {
    computedConnectedAtsJob.value = job;
  } else {
    computedProject.value.atsJobId = atsJobId;
    computedProject.value.name = job.name;
  }
}

async function loadProjectNames() {
  const { data } = await ProjectsApi.nameSearch({
    page_size: 10000,
    max: 10000,
    sortField: "name",
    sortOrder: "asc",
  });
  projectOptions.value = data.projects;
};

onMounted(() => {
  loadProjectNames();
});

</script>

<template>
  <div>
    <el-alert
      v-if="!currentTeam.atsName"
      class="mb-8"
      type="info"
      :closable="false"
      show-icon
    >
      <template slot="title">
        <router-link :to="{ name: 'ats-settings' }">
          Connect to an ATS
        </router-link> to enable linking jobs with jobs.
      </template>
    </el-alert>

    <el-form-item prop="name" class="mb-8">
      <p class="m-0">Job title</p>
      <el-autocomplete
        v-model="computedProject.name"
        class="w-100"
        :fetch-suggestions="querySearch"
        placeholder="Enter new or search for existing"
        :trigger-on-focus="false"
        @select="viewProject"
      />
    </el-form-item>

    <el-form-item v-if="currentTeam.isAgency" class="mb-8">
      <p class="m-0">Company Name</p>
      <el-input
        v-model="computedProject.companyName"
        placeholder="Enter the name of the company associated with the job"
      />
    </el-form-item>

    <template v-if="currentTeam.atsName">
      <el-form-item v-if="currentTeam.atsName">
        <div class="form-item">
          <el-tooltip placement="left" :content="atsJobTooltip" :open-delay="500">
            <el-switch v-model="computedHasAtsJob"></el-switch>
          </el-tooltip>
          <p class="item-label inline-label">Sync with {{ currentUser.primaryTeamAtsName }}</p>

          <el-alert type="info" class="w-auto m-16 p-16 mb-16" show-icon>
            Syncing with this job in {{ currentUser.primaryTeamAtsName }} allows you to automatically:
            <ul class="mt-8">
              <li>Calibrate comprehensive searches and immediately see relevant recommended candidates</li>
              <li>Use our AI engine to help rank and review top applicants</li>
              <li>Generate personalized outreach and campaigns proven to engage prospective candidates</li>
              <li>Add candidates, sync outreach, status, and other critical data points between platforms</li>
            </ul>
          </el-alert>
        </div>
      </el-form-item>

      <el-form-item v-if="computedHasAtsJob" prop="atsJobId">
        <div class="form-item">
          <el-select
            class="w-100"
            :value="computedProject.atsJobId"
            :placeholder="selectedJobPlaceholder"
            filterable
            clearable
            @change="handleAtsJobChange"
          >
            <el-option
              v-for="job in computedProject.atsJobOptions"
              :key="job.id"
              :label="jobLabel(job)"
              :value="job.id"
            >
              <i class="far fa-link" style="margin-right: 8px;" :style="jobIconStyle(job)"></i>
              <span>{{ jobLabel(job) }}</span>
            </el-option>
          </el-select>
        </div>
      </el-form-item>
    </template>

    <template v-if="!computedHasAtsJob">
      <el-form-item prop="location" class="mb-8">
        <p class="m-0">Location</p>
        <el-select
          ref="location"
          v-model="computedProject.location"
          class="w-100"
          clearable
          filterable
          remote
          reserve-keyword
          :remote-method="remoteLocationQuery"
          :loading="isLoadingRemoteQuery"
        >
          <el-option
            v-for="location in locationOptions"
            :key="location.id"
            :value="location.id"
            :label="location.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item v-if="isReferralsEnabled" prop="department" class="mb-8">
        <p class="m-0">Department</p>
        <el-select
          v-model="computedProject.departmentId"
          class="w-100"
          filterable
          clearable
        >
          <el-option
            v-for="department in computedProject.departmentOptions"
            :key="department.id"
            :label="department.name"
            :value="department.id"
          />
        </el-select>
      </el-form-item>

      <el-form-item v-if="isReferralsEnabled && computedProject.atsUserOptions?.length > 1">
        <p class="m-0">Primary recruiter</p>
        <el-select
          v-if="currentUser.primaryTeamAtsName"
          v-model="computedProject.primaryRecruiterAtsUserId"
          class="w-100"
          filterable
          reserve-keyword
        >
          <el-option
            v-for="atsUser in computedProject.atsUserOptions"
            :key="atsUser.id"
            :label="atsUser.name"
            :value="atsUser.id"
          >
          </el-option>
        </el-select>

        <el-select
          v-else
          v-model="computedProject.primaryRecruiterUserId"
          class="w-100"
          filterable
          reserve-keyword
        >
          <el-option
            v-for="teammate in allTeammatesAndMe"
            :key="teammate.id"
            :label="teammate.name"
            :value="teammate.id"
          >
            <UserAvatar
              :image-url="teammate.avatarUrl"
              :full-name="teammate.name"
            />
          </el-option>
        </el-select>
      </el-form-item>

      <p class="m-0 mb-4">Job description</p>

      <el-form-item id="message-editor-wrapper" prop="postingDescription">
        <MessageEditor
          v-model="computedProject.postingDescription"
          :placeholder="descriptionPlaceholder"
          :show-toolbar="isReferralsEnabled"
        />
      </el-form-item>
    </template>
  </div>
</template>

<style scoped lang="scss">
#message-editor-wrapper {
  padding: 16px;
  border: 1px solid $tf-global-border-color;
}

::v-deep .quillWrapper {
  height: 160px;
}
::v-deep .ql-picker-label {
  display: flex !important;
  align-items: center;
  margin-top: 2px;
}
::v-deep .ql-editor.ql-blank::before {
  left: 0 !important;
}
.el-form-item {
  margin-bottom: 16px;
}
.form-item {
  .item-label {
    margin: 0;
  }
  .inline-label {
    display: inline;
    margin-left: 16px;
  }
}
::v-deep .el-form-item__content {
  line-height: 32px;
}
</style>
