<script setup>
import { computed, useSlots } from "vue";

const props = defineProps({
  atsName: {
    type: String,
    required: true,
  },
  problem: {
    type: Boolean,
    default: false,
  },
});

const slots = useSlots();

const fromImageContext = (image) => {
  const images = require.context(
    "@/assets/images/vendor-logos",
    false,
    /\.svg$/
  );

  try {
    return images(`./${image}.svg`);
  } catch (error) {
    return images("./fallback.svg");
  }
};

const imageSrc = computed(() => {
  if (!props.atsName) {
    return null;
  }

  const atsNameLowerCased = props.atsName.toLowerCase().replace(/ /g, "-");
  return fromImageContext(atsNameLowerCased);
});

const problemIcon = computed(() => {
  return fromImageContext("problem");
});
</script>

<template>
  <div>
    <div class="logo-container w-100">
      <img class="w-100" :src="imageSrc" :alt="atsName" />
      <img v-if="props.problem" class="w-24 problem" :src="problemIcon" />
    </div>

    <el-divider v-if="slots.description" class="my-24" />

    <slot name="description"></slot>
  </div>
</template>

<style scoped>
.logo-container {
  position: relative;
}

.problem {
  position: absolute;
  bottom: 0;
  right: 0;
}
</style>
