export const CHANNEL_BASE_OPTIONS = [
  { id: "outbound", name: "Outbound" },
];

export const ORIGIN_BASE_OPTIONS = [
  { id: "extension", name: "Extension" },
  { id: "stream", name: "Stream" },
  { id: "csv", name: "CSV upload" },
  { id: "recommendations", name: "Teamable recommended" },
];

export const TALENT_COMMUNITY_AREA_OF_INTEREST_OPTIONS = [
  { label: "Accounting & Finance", value: "accounting_and_finance" },
  { label: "Call Center", value: "call_center" },
  { label: "Casino", value: "casino" },
  { label: "College Programs", value: "college_programs" },
  { label: "Corporate", value: "corporate" },
  { label: "Entertainment/Event Production", value: "entertainment_event_production" },
  { label: "Facility Maintenance", value: "facility_maintenance" },
  { label: "Food and Beverage", value: "food_and_beverage" },
  { label: "Hotel Operations", value: "hotel_operations" },
  { label: "Human Resources & Legal and Risk Management", value: "human_resources_and_legal_and_risk_management" },
  { label: "Retail & Procurement", value: "retail_and_procurement" },
  { label: "Sales & Marketing", value: "sales_and_marketing" },
  { label: "Security", value: "security" },
  { label: "Spa & Recreation", value: "spa_and_recreation" },
  { label: "Technology", value: "technology" },
  { label: "Transportation/Aviation", value: "transportation_aviation" },
];

export const TALENT_COMMUNITY_PREFERRED_LOCATION_OPTIONS = [
  { label: "Nevada", value: "Nevada" },
  { label: "New Jersey", value: "New Jersey" },
  { label: "Mississippi", value: "Mississippi" },
  { label: "Michigan", value: "Michigan" },
  { label: "Ohio", value: "Ohio" },
  { label: "New York", value: "New York" },
  { label: "Maryland", value: "Maryland" },
  { label: "Massachusetts", value: "Massachusetts" },
  { label: "International", value: "International" },
];

export const AVG_RECENT_TENURE_OPTIONS = [
  { name: "very-short", id: "very-short-0-1" },
  { name: "short", id: "short-1-3" },
  { name: "medium", id: "medium-3-6" },
  { name: "long", id: "long-6-10" },
  { name: "very-long", id: "very-long-10-inf" },
];

export const EMP_SIZE_OPTIONS = [
  { label: "Very small (1-10)", value: "very-small-1-10" },
  { label: "Small (11-150)", value: "small-11-150" },
  { label: "Medium (151-1500)", value: "medium-151-1500" },
  { label: "Large (1501-9999)", value: "large-1501-9999" },
  { label: "Very large (10000+)", value: "very-large-10000-inf" },
];

export const EMP_GROWTH_RATE_OPTIONS = [
  { label: "Negative", value: "negative-inf-0" },
  { label: "Static (0)", value: "static-0" },
  { label: "Slow (0-10)", value: "small-0-10" },
  { label: "Medium (10-25)", value: "medium-10-25" },
  { label: "Fast (25-99)", value: "large-25-99" },
  { label: "Very fast (100+)", value: "very-large-100-inf" },
];

// keep list consistent with topfunnel-web/app/services/crunchbase/metrics_service.rb
export const EARLY_AT_COMPANY_OPTIONS = [
  { name: "very-early", id: "very-early-0-1" },
  { name: "early", id: "early-1-3" },
  { name: "middle", id: "mid-3-6" },
  { name: "late", id: "late-6-10" },
  { name: "very-late", id: "very-late-10-inf" },
];

export const CANDIDATE_STATUS_OPTIONS = [
  {
    value: "sourced",
    label: "Sourced",
  },
  {
    value: "reached",
    label: "Reached",
  },
  {
    value: "responded",
    label: "Responded",
  },
  {
    value: "interested",
    label: "Interested",
  },
  {
    value: "interview",
    label: "Interviewed",
  },
  {
    value: "hired",
    label: "Hired",
  },
];

